/**
* common.js
*/

/* ----------------------------------------------------------
init
---------------------------------------------------------- */
$(function () {
  $('.slider-banner').slick({
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });
  $('.arrow-left').click(function(){
    $('.slider-banner').slick('slickPrev');
  })

  $('.arrow-right').click(function(){
    $('.slider-banner').slick('slickNext');
  })
  $('.slider-main').slick({
    dots: false,
    arrows: false,
    autoplay:true,
    autoplaySpeed:6666
  });



  /** スクロールに合わせて header の高さを変更 */

  const nav_local = $('#nav_local');
  const homeClass = 'home';
  const mediaQuery = matchMedia('(max-width: 812px)');
  // ページが読み込まれた時に実行
  handle(mediaQuery);
  // ウィンドウサイズが変更されても実行されるように
  mediaQuery.addListener(handle);
  let pixel;
  const event_point = Math.round( nav_local.offset().top ) - pixel;

  function handle(mq)
  {
    if (mq.matches) {
      pixel = 60;
    } else {
      pixel = 140;
    }
  }

/*
  $(window).on('scroll', function(){
    const scrollVol = $(this).scrollTop();
    if( event_point < scrollVol ){
      $('body').removeClass(homeClass);
    } else {
      $('body').addClass(homeClass);
    }
  });
*/


  // slider 麻の葉のランダム点滅
  const pix = document.getElementsByClassName('a');

  for ( let i = 0; i < pix.length; i++ ) {
    pix[i].style.animationDelay = Math.ceil(Math.random()*5000)+'ms';
  }

}); // end of jQuery
